import React from 'react';
import FleetTrackingMap from './FleetTrackingMap';
import './index.css';

function App() {
  return (
    <div className="App">
      <FleetTrackingMap />
    </div>
  );
}

export default App;