import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const gameUtils = {
  ets2: {
    tileUrl: "https://map.truckershub.in/ets2/Tiles/{z}/{x}/{y}.png",
    convertXY: (x, y) => {
      let o = -94621.8047, n = 79370.13, l = -80209.1641, i = 93782.77, a = 131072, r = 131072;
      return [(x - o) / (n - o) * a, (y - l) / (i - l) * r];
    },
    filterDrivers: (data) => !(data.telemetry.mods || []).find(f => f.package.startsWith("promods-def")) && !data.telemetry.game.isTruckersMP
  },
  ets2promods: {
    tileUrl: "https://map.truckershub.in/ets2promods/Tiles/{z}/{x}/{y}.png",
    convertXY: (x, y) => {
      let o = -96450.79, n = 205684.1, l = -178324.031, i = 123810.844, a = 131072, r = 131072;
      return [(x - o) / (n - o) * a, (y - l) / (i - l) * r];
    },
    filterDrivers: (data) => {
      return ((data.telemetry.mods || []).find(f => f.package.startsWith("promods-def")) && !data.telemetry.game.isTruckersMP) ||
             (data.telemetry.game.isTruckersMP && (data.telemetry.game.multiplayer?.server === "ProMods" || data.telemetry.game.multiplayer?.server === "ProMods Arcade"));
    }
  },
  ats: {
    tileUrl: "https://map.truckershub.in/ats/Tiles/{z}/{x}/{y}.png",
    convertXY: (x, y) => {
      let o = -127721.344, n = 20049.6563, l = -72181.5, i = 75589.5, a = 131072, r = 131072;
      return [(x - o) / (n - o) * a, (y - l) / (i - l) * r];
    },
    filterDrivers: (data) => {
      return data.telemetry.game.isTruckersMP ? 
             (data.telemetry.game.multiplayer?.server !== "ProMods" && data.telemetry.game.multiplayer?.server !== "ProMods Arcade") :
             !(data.telemetry.mods || []).find(f => f.package.startsWith("promods-def"));
    }
  },
  tmp: {
    tileUrl: "https://map.truckershub.in/tmp/Tiles/{z}/{x}/{y}.png",
    convertXY: (x, y) => {
      let o = -94621.8047, n = 79370.13, l = -80209.1641, i = 93782.77, a = 131072, r = 131072;
      return [(x - o) / (n - o) * a, (y - l) / (i - l) * r];
    },
    filterDrivers: (data) => data.telemetry.game.isTruckersMP && data.telemetry.game.multiplayer?.server !== "ProMods" && data.telemetry.game.multiplayer?.server !== "ProMods Arcade"
  }
};

const FleetTrackingApp = () => {
  const [selectedGame, setSelectedGame] = useState('ets2');
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markersRef = useRef({});
  const socketRef = useRef(null);

  useEffect(() => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.remove();
    }

    const map = L.map("mapts", { crs: L.CRS.Simple, zoomControl: false });
    mapInstanceRef.current = map;

    const maxZoom = 8;
    const bounds = L.latLngBounds(map.unproject([0, 131072], maxZoom), map.unproject([131072, 0], maxZoom));

    L.tileLayer(gameUtils[selectedGame].tileUrl, {
      maxZoom: maxZoom,
      minZoom: 0,
      minNativeZoom: 0,
      maxNativeZoom: maxZoom,
      tileSize: 512,
      reuseTiles: true,
      bounds: bounds
    }).addTo(map);

    map.setView([312, 312], 0);
    map.setMaxBounds(bounds);

    // Add watermark
    

    // Initialize WebSocket
    if (socketRef.current) {
      socketRef.current.close();
    }

    const socket = new WebSocket('wss://gateway.truckershub.in/');
    socketRef.current = socket;
    
    socket.addEventListener("open", () => {
      socket.send(JSON.stringify({
        type: "AUTH",
        data: {
          subscribe_to: {
            to_company: 55,
          },
          game: selectedGame === 'tmp' ? 'ets2' : (selectedGame == 'ets2promods' ? 'ets2' : selectedGame),
        },
      }));
    });

    socket.addEventListener("message", ({ data: message }) => {
      const { type, data } = JSON.parse(message);

      if (type === "AUTH_ACK") {
        setInterval(() => {
          socket.send(JSON.stringify({ type: "HEART_BEAT" }));
        }, data.heartbeat_interval * 1000);
      } else if (type === "PLAYER_DATA") {
        if (gameUtils[selectedGame].filterDrivers(data)) {
          setDrivers(prevDrivers => {
            const updatedDrivers = prevDrivers.filter(d => d.driver !== data.driver);
            return [...updatedDrivers, data].sort((a, b) => a.telemetry.steam.name.localeCompare(b.telemetry.steam.name));
          });
        }
      }
    });

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [selectedGame]);

  useEffect(() => {
    setDrivers([]);  // Clear the driver list when the selected game changes
  }, [selectedGame]);

  useEffect(() => {
    if (!mapInstanceRef.current) return;

    Object.keys(markersRef.current).forEach(driverId => {
      if (!drivers.some(d => d.driver === driverId)) {
        mapInstanceRef.current.removeLayer(markersRef.current[driverId]);
        delete markersRef.current[driverId];
      }
    });

    drivers.forEach(driver => {
      const [x, y] = gameUtils[selectedGame].convertXY(driver.telemetry.truck.position.X, driver.telemetry.truck.position.Z);
      const pos = mapInstanceRef.current.unproject([x, y], 8);
      const isSelected = selectedDriver && selectedDriver.driver === driver.driver;
      const markerColor = isSelected ? '#e30000' : '#2ecc71';

      if (markersRef.current[driver.driver]) {
        markersRef.current[driver.driver].setLatLng(pos);
        markersRef.current[driver.driver].setIcon(L.divIcon({
          className: 'player',
          html: `<div class='circle' id='${driver.driver}' style='background-color: ${markerColor}; transform: rotate(${parseInt(((0 - driver.telemetry.truck.orientation.heading) * 360))}deg);'></div>`
        }));
      } else {
        const marker = L.marker(pos, {
          icon: L.divIcon({
            className: 'player',
            html: `<div class='circle' id='${driver.driver}' style='background-color: ${markerColor}; transform: rotate(${parseInt(((0 - driver.telemetry.truck.orientation.heading) * 360))}deg);'></div>`
          })
        }).addTo(mapInstanceRef.current);
        marker.on('click', () => handleDriverClick(driver));
        markersRef.current[driver.driver] = marker;
      }
    });
  }, [drivers, selectedDriver, selectedGame]);

  const handleDriverClick = (driver) => {
    setSelectedDriver(driver);
    const [x, y] = gameUtils[selectedGame].convertXY(driver.telemetry.truck.position.X, driver.telemetry.truck.position.Z);
    const pos = mapInstanceRef.current.unproject([x, y], 8);
    mapInstanceRef.current.setView(pos, 5);
  };

  const formatDriverInfo = (data) => {
    const server = data.game.multiplayer ? `<i class="fa-solid fa-server"></i>${data.game.multiplayer.server}` : '';
    return `
      <div class="driver-info">
        <div>${server}</div>
        <div><i class="fa-solid fa-truck"></i>${data.truck.make.name} ${data.truck.model.name}</div>
        <div><i class="fa-solid fa-gas-pump"></i>${Math.round(data.truck.fuel.value)}/${Math.round(data.truck.fuel.capacity)}Л | ${Math.round(data.truck.fuel.range)} км осталось</div>
        <div><i class="fa-solid fa-gauge-high"></i>${data.truck.speed.kph} km/h</div>
        ${data.job.plannedDistance.km > 0 ? `
          <div><i class="fa-solid fa-route"></i>${data.job.source.city.name}, ${data.job.source.company.name} <i class="fa-solid fa-angles-right"></i> ${data.job.destination.city.name}, ${data.job.destination.company.name}</div>
          <div><i class="fa-solid fa-truck-ramp-box"></i>${data.job.cargo.name} (${Math.round(data.job.cargo.mass / 1000)} Т)</div>
          <div><i class="fa-solid fa-road"></i>${data.job.plannedDistance.km} км</div>
          <div><i class="fa-solid fa-location-arrow"></i>${Math.round(data.navigation.distance / 1000)} км осталось</div>
          <div><i class="fa-solid fa-shop"></i>${data.job.market.name || "N/A"}</div>
        ` : '<div>No active job</div>'}
      </div>
    `;
  };

  const calculateProgress = (data) => {
    if (data.job.plannedDistance.km > 0) {
      const totalDistance = data.job.plannedDistance.km;
      const remainingDistance = data.navigation.distance / 1000;
      return Math.min(100, Math.round(((totalDistance - remainingDistance) / totalDistance) * 100));
    }
    return 0;
  };

  return (
    <div className="fleet-tracking-container">
      <div className="game-menu">
        {Object.keys(gameUtils).map(game => (
          <button 
            key={game} 
            onClick={() => setSelectedGame(game)}
            className={selectedGame === game ? 'active' : ''}
          >
            {game.toUpperCase()}
          </button>
        ))}
      </div>
      <div className="content-wrapper">
        <div className="sidebar">
          <h2>Водители</h2>
          <div className="driver-list">
            {drivers.map(driver => (
              <div key={driver.driver} className="driver-item" id={`driver-${driver.driver}`}>
                <div onClick={() => handleDriverClick(driver)}>
                  <span>{driver.telemetry.steam.name}</span>
                  <span>{driver.telemetry.truck.make.name}  {driver.telemetry.truck.model.name}</span>
                </div>
                {selectedDriver && selectedDriver.driver === driver.driver && (
                  <>
                    <div className="progress-bar">
                      <div className="progress" style={{width: `${calculateProgress(driver.telemetry)}%`}}></div>
                    </div>
                    <div className="job-info" dangerouslySetInnerHTML={{ __html: formatDriverInfo(driver.telemetry) }} />
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
        <div id="mapts"></div>
      </div>
    </div>
  );
};

export default FleetTrackingApp;
